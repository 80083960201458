import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromcapital'
})
export class FromcapitalPipe implements PipeTransform {

  transform(value: string): string {
    return value[0].toUpperCase()+value.substr(1);
  }

}
