import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncnum'
})
export class TruncnumPipe implements PipeTransform {

  transform(value: number): string {
    if (!Number(value))
      return value + '';
    let newvalue = Math.trunc(value * 1000) / 1000;
    if (newvalue != value)
      return newvalue + '...';
    else
      return value + '';
  }

}
