import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FromcapitalPipe } from './_pipes/fromcapital.pipe';
import { TruncnumPipe } from './_pipes/truncnum.pipe';
import { FirstnamePipe } from './_pipes/firstname.pipe';


@NgModule({
  declarations: [FromcapitalPipe, TruncnumPipe, FirstnamePipe,],
  imports: [
    CommonModule,
  ],
  exports: [
    FromcapitalPipe, TruncnumPipe, FirstnamePipe,
  ]
})
export class CommonComponentsModule { }
